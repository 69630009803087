<template>
  <div class="card-main">
    <el-row :gutter="30">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="4"
        :xl="4"
        v-for="(item, index) in cardData"
        :key="index"
      >
        <div class="card-container" @click="jumpToWebsite(item.webLinkUrl)">
          <div class="card-header" v-if="isCanEdit">
            <el-button type="text" @click.stop="handleEditCard(item)"
              >编辑</el-button
            >
            <el-button type="text" @click.stop="handleDelCard(item)"
              >删除</el-button
            >
          </div>
          <div class="card_container">
            <div class="line_top">
              <p><img :src="item.webLogo" alt="" /></p>
              <span class="ellipsis-text">{{ item.webName }}</span>
            </div>
            <p class="ellipsis-text bot_title" :title="item.webDescribe">
              {{ item.webDescribe }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="编辑网站"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
    >
      <el-form :model="myForm">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
            <el-form-item label="网站名称" prop="webName">
              <el-input v-model="myForm.webName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
            <el-form-item label="网站链接" prop="webLinkUrl">
              <el-input v-model="myForm.webLinkUrl"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
            <el-form-item label="网站描述" prop="webDescribe">
              <el-input v-model="myForm.webDescribe"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="24">
            <el-form-item label="网站logo" prop="webLogo">
              <el-input type="textarea" v-model="myForm.webLogo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import allData from '@/data/tool.json'

export default {
  name: 'EditWebsiteDialog',
  data() {
    return {
      cardData: [],
      dialogFormVisible: false,
      myForm: {},
      isCanEdit: false
    }
  },
  props: {
    dataResource: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted() {
    this.loadData()
    this.$EventBus.$on('switchChanged', msg => {
      this.isCanEdit = msg
    })
  },
  methods: {
    onSave() {
      this.dialogFormVisible = false
      const url = `http://localhost:3000/list/${this.myForm.id}`
      const params = {
        ...this.myForm
      }
      this.$http
        .put(url, params)
        .then(res => {
          this.loadData()
          this.$message.success('更新成功')
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    jumpToWebsite(webLinkUrl) {
      window.open(webLinkUrl, '_blank')
    },
    handleDelCard(item) {
      const url = `http://localhost:3000/list/${item.id}`
      this.$http
        .delete(url)
        .then(res => {
          this.isCanEdit = !this.isCanEdit
          this.loadData()
          this.$message.success('删除成功')
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    handleEditCard(row) {
      this.dialogFormVisible = true
      this.myForm = { ...row }
    },
    loadData() {
      this.cardData = allData.list.filter(item => {
        return (
          item.selectedOptions[0] === this.dataResource[0] &&
          item.selectedOptions[1] === this.dataResource[1]
        )
      })
      // const url = 'http://localhost:3000/list'
      // this.$http
      //   .get(url)
      //   .then(res => {
      //     this.cardData = res.data.filter(item => {
      //       return (
      //         item.selectedOptions[0] === 'movies' &&
      //         item.selectedOptions[1] === '1-1'
      //       )
      //     })
      //   })
      //   .catch(err => {
      //     this.$message.error(err)
      //   })
    }
  }
}
</script>

<style lang="less">
@import '~@/styles/index.less';
@keyframes animate {
  0% {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}
.card-main {
  margin-top: 20px;
  .card-container {
    border: 1px solid rgb(225, 222, 222);
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    // background: url('~@/assets/home.jpg') fixed 0 / cover;
    &::before {
      content: '';
      position: absolute;
      width: 125%;
      height: 40%;
      // background: linear-gradient(360deg, #00ccff, #d400d4);
      // animation: animate 4s linear infinite;
      // background: url('~@/assets/home.jpg') fixed 0 / cover;
    }
    &::after {
      content: '';
      position: absolute;
      inset: 4px;
      background: url('~@/assets/home.jpg') fixed 0 / cover;

      // background-image: url('~@/assets/paper.png');
      // background: #002033;
      border-radius: 4px;
      z-index: 2;
    }
    &:hover::before {
      background: linear-gradient(360deg, #00ccff, #d400d4);
      animation: animate 4s linear infinite;
      z-index: 1;
    }
    .card-header {
      height: 20%;
      padding: 10px;
      position: absolute;
      z-index: 99;
      right: 0;
      top: 0;
    }
    .card_container {
      width: 100%;
      position: relative;
      color: #fff;
      z-index: 98;
      .line_top {
        margin-top: 20px;
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;
        color: #229dd6;
        font-weight: bold;
        p {
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 20px;
          img {
            width: 50px;
            height: 50px;
          }
        }
        span {
          width: 50%;
        }
      }
      .bot_title {
        padding: 10px 20px;
        font-size: 14px;
        color: rgb(189, 182, 182);
      }
    }
  }
}
</style>
